<template>
  <LayoutMainPages>
    <section class="drop-container">
      <div class="page-inner">
        <div class="display-and-info">
          <div class="drop-viewer">
            <AssetDisplay
              @onGoBack="goBack"
              @onShareEvent="emitShareEvent"
              :player="lodashGet(drop, 'asset.associated_players[0].name', '')"
              :imgBg="lodashGet(drop, 'asset.img_front', '')"
              :imgFront="lodashGet(drop, 'asset.img_front', '')"
              :imgBack="lodashGet(drop, 'asset.img_back', '')"
              :isLoading="isSkeletonLoading"
              :isOnFavoritesList="isOnFavoritesList"
            />
          </div>

          <div class="side-panel">
            <DropsInfo
              @onShareEvent="emitShareEvent"
              :drop="drop"
              :isLoading="isSkeletonLoading"
              :isOnFavoritesList="isOnFavoritesList"
            />

            <div class="drop-actions">
              <SkeletonLoading
                v-if="isSkeletonLoading"
                borderRadius="24px"
                height="44px"
              />
              <ButtonV2
                v-else
                @onClick="openBuyModal"
                :label="$tc('asset_detail.buy_now')"
                :inactive="!isDropLive"
                size="medium"
                version="primary"
                wide
              />
            </div>

            <TradeCardLadder
              class="mt-s48"
              :cardladder="lodashGet(drop, 'asset.cardladder', {})"
              :isLoading="isSkeletonLoading"
            />

            <DropsWhatAre
              class="mt-s48"
            />
          </div>
        </div>
      </div>
    </section>
  </LayoutMainPages>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';

import { AssetDisplay } from '@/modules/asset';
import { DropsInfo, DropsWhatAre } from '@/modules/drops';
import { TradeCardLadder } from '@/modules/trade';
import { ButtonV2, LayoutMainPages } from '@/components/misc';

import DROP_QUERY from '@/graphql/queries/Drop.gql';
import { formatDrop } from '@/modules/drops/utils/formatDrop';

import DROP_STATUS from '@/enums/DropStatus';

export default {
  name: 'Drop',
  
  components: {
    AssetDisplay,
    ButtonV2,
    DropsInfo,
    DropsWhatAre,
    LayoutMainPages,
    TradeCardLadder,
  },

  data() {
    return {
      isOnFavoritesList: false,
      isSkeletonLoading: true,
      timeNowInterval: null,
    };
  },

  mounted() {
    if (!this.globalIsLoading) {
      this.loaded();
    }
  },

  beforeDestroy() {
    clearInterval(this.timeNowInterval);
  },

  computed: {
    ...mapState('api', ['current_user', 'drop']),
    ...mapGetters('drops', ['getDropAssetImages', 'getCurrentTime']),

    dropEventData() {
      return {
        ...this.specs,
        asset_id: this.drop.asset.id,
        asset_ticker: this.drop.asset.ticker,
        asset_value_usd: this.drop.asset_value_usd,
        drop_type: this.drop.type,
        end_time: this.drop.end_time,
        id_hr: this.drop.id_hr,
        id: this.drop.id,
        player_id_hr: this.drop.asset.associated_players[0].id_hr,
        player_id: this.drop.asset.associated_players[0].id,
        running: this.drop.running,
        start_time: this.drop.start_time,
      };
    },

    dropStatus() {
      return formatDrop(this.drop, this.getCurrentTime).status;
    },

    isDropLive() {
      return this.dropStatus === DROP_STATUS.LIVE;
    },

    isUserAuthenticated(){
      return this.$store.state.api.isAuthenticated;
    },
  },

  methods: {
    ...mapActions('drops', ['updateCurrentTime']),

    checkFavorite() {
      const watchListsThisAssetIsOn = this.lodashGet(this.drop, 'asset.current_user_watch_lists', []);
      this.isOnFavoritesList = watchListsThisAssetIsOn.some(watchlist => watchlist.name === 'favorites');
    },

    async emitShareEvent() {
      await this.$store.dispatch('events/track', {
        event: 'ASSET_DROP_SHARED',
        variables: {
          ...this.dropEventData,
        },
      });
    },

    goBack() {
      this.$router.go(-1);
    },

    async loadData() {
      this.isLoading = true;

      await this.$store.dispatch('api/refreshPageData', {
        $apollo: this.$apollo,
        query: DROP_QUERY,
        variables: {
          drop_id: Number(this.$route.params.drop_id),
        },
      });

      this.isLoading = false;
    },

    async loaded() {
      if (this.lodashIsEmpty(this.drop)) {
        await this.showError(new Error('unknown_pair'));
        return this.goToInternalPageGlobal('/');
      }

      this.isSkeletonLoading = false;

      this.checkFavorite();

      this.timeNowInterval = setInterval(() => {
        this.updateCurrentTime(new Date().getTime());
      }, 1000);

      await this.$store.dispatch('events/track', {
        event: 'ASSET_DROP_VIEWED',
        variables: {
          ...this.dropEventData,
        },
      });
    },

    openBuyModal() {
      this.showModal('DropBuy', {
        currentUser: this.current_user,
        drop: this.drop,
        dropEventData: this.dropEventData,
      });
    },

    routerToLogin(){
      return this.goToInternalPageGlobal('/login');
    },
  },
  
  watch: {
    globalIsLoading(newValue, oldValue) {
      if (oldValue || !newValue) {
        this.$nextTick(() => {
          this.loaded();
        });
      }
    },
  },
};
</script>

<style scoped>
.drop-container {
  @apply flex flex-row justify-center w-full;
}
.page-inner {
  @apply flex flex-col w-full py-s40;
  max-width: 1242px;
}
.display-and-info {
  @apply flex flex-row;
}
.drop-viewer {
  @apply flex-1 flex-grow;
  min-width: 400px;
  max-width: 580px;
}
.side-panel {
  @apply w-full ml-s40 box-border;
  max-width: 580px;
}
.drop-actions {
  @apply flex flex-row mt-s40;
  max-width: 177px;
}

@screen lg {
  .page-inner {
    @apply flex-col pt-0;
    max-width: 100vw;
    margin-bottom: 85px;
  }
  .display-and-info {
    @apply flex-col justify-center;     
  }
  .drop-viewer {
    min-width: 0;
    max-width: 100vw;
    height: 520px;
  }
  .side-panel {
    @apply px-s20 ml-s0 max-w-full;
  }
  .side-panel .button-v2 {
    min-width: auto;  
  }
  .drop-actions {
    @apply
      w-full
      p-s20
      mt-s0
      bg-background-page
      z-component
      fixed
      bottom-0
      left-0
    ;
    height: 85px;
    max-width: unset;
  }
}
</style>
